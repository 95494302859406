import React, { FC } from 'react';

interface ImgCastom {
	alt?: string;
	role?: string;
}

export const LayoutToggleBtn: FC<ImgCastom> = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.83317 6.04102C6.17835 6.04102 6.45817 6.32084 6.45817 6.66602L6.45817 13.3327C6.45817 13.6779 6.17835 13.9577 5.83317 13.9577C5.48799 13.9577 5.20817 13.6779 5.20817 13.3327L5.20817 6.66602C5.20817 6.32084 5.48799 6.04102 5.83317 6.04102Z"
				fill="inherit"
			/>
			<path
				d="M14.7915 9.99935C14.7915 10.3445 14.5117 10.6243 14.1665 10.6243H9.84205L11.2751 12.0574C11.5192 12.3015 11.5192 12.6972 11.2751 12.9413C11.031 13.1854 10.6353 13.1854 10.3912 12.9413L7.89123 10.4413C7.77402 10.3241 7.70817 10.1651 7.70817 9.99935C7.70817 9.83359 7.77402 9.67462 7.89123 9.55741L10.3912 7.05741C10.6353 6.81333 11.031 6.81333 11.2751 7.05741C11.5192 7.30149 11.5192 7.69721 11.2751 7.94129L9.84205 9.37435H14.1665C14.5117 9.37435 14.7915 9.65417 14.7915 9.99935Z"
				fill="inherit"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.95202 1.04102C8.02836 1.04101 6.52083 1.041 5.34459 1.19914C4.14065 1.361 3.19063 1.69879 2.44495 2.44446C1.69928 3.19014 1.36149 4.14016 1.19963 5.3441C1.04148 6.52034 1.04149 8.02787 1.0415 9.95153V10.0472C1.04149 11.9708 1.04148 13.4784 1.19963 14.6546C1.36149 15.8585 1.69928 16.8086 2.44495 17.5542C3.19063 18.2999 4.14065 18.6377 5.34459 18.7996C6.52083 18.9577 8.02836 18.9577 9.95203 18.9577H10.0476C11.9713 18.9577 13.4788 18.9577 14.6551 18.7996C15.859 18.6377 16.8091 18.2999 17.5547 17.5542C18.3004 16.8086 18.6382 15.8585 18.8 14.6546C18.9582 13.4784 18.9582 11.9708 18.9582 10.0472V9.95154C18.9582 8.02788 18.9582 6.52034 18.8 5.3441C18.6382 4.14016 18.3004 3.19014 17.5547 2.44446C16.8091 1.69879 15.859 1.361 14.6551 1.19914C13.4788 1.041 11.9713 1.04101 10.0477 1.04102H9.95202ZM3.32883 3.32835C3.80355 2.85363 4.44582 2.58122 5.51115 2.43799C6.59447 2.29234 8.01798 2.29102 9.99984 2.29102C11.9817 2.29102 13.4052 2.29234 14.4885 2.43799C15.5539 2.58122 16.1961 2.85363 16.6708 3.32835C17.1456 3.80306 17.418 4.44533 17.5612 5.51066C17.7068 6.59398 17.7082 8.0175 17.7082 9.99935C17.7082 11.9812 17.7068 13.4047 17.5612 14.488C17.418 15.5534 17.1456 16.1956 16.6708 16.6704C16.1961 17.1451 15.5539 17.4175 14.4885 17.5607C13.4052 17.7064 11.9817 17.7077 9.99984 17.7077C8.01798 17.7077 6.59447 17.7064 5.51115 17.5607C4.44582 17.4175 3.80355 17.1451 3.32883 16.6704C2.85412 16.1956 2.58171 15.5534 2.43848 14.488C2.29283 13.4047 2.2915 11.9812 2.2915 9.99935C2.2915 8.0175 2.29283 6.59398 2.43848 5.51066C2.58171 4.44533 2.85412 3.80306 3.32883 3.32835Z"
				fill="inherit"
			/>
			<path
				d="M5.83317 6.04102C6.17835 6.04102 6.45817 6.32084 6.45817 6.66602L6.45817 13.3327C6.45817 13.6779 6.17835 13.9577 5.83317 13.9577C5.48799 13.9577 5.20817 13.6779 5.20817 13.3327L5.20817 6.66602C5.20817 6.32084 5.48799 6.04102 5.83317 6.04102Z"
				stroke="inherit"
			/>
			<path
				d="M14.7915 9.99935C14.7915 10.3445 14.5117 10.6243 14.1665 10.6243H9.84205L11.2751 12.0574C11.5192 12.3015 11.5192 12.6972 11.2751 12.9413C11.031 13.1854 10.6353 13.1854 10.3912 12.9413L7.89123 10.4413C7.77402 10.3241 7.70817 10.1651 7.70817 9.99935C7.70817 9.83359 7.77402 9.67462 7.89123 9.55741L10.3912 7.05741C10.6353 6.81333 11.031 6.81333 11.2751 7.05741C11.5192 7.30149 11.5192 7.69721 11.2751 7.94129L9.84205 9.37435H14.1665C14.5117 9.37435 14.7915 9.65417 14.7915 9.99935Z"
				stroke="inherit"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.95202 1.04102C8.02836 1.04101 6.52083 1.041 5.34459 1.19914C4.14065 1.361 3.19063 1.69879 2.44495 2.44446C1.69928 3.19014 1.36149 4.14016 1.19963 5.3441C1.04148 6.52034 1.04149 8.02787 1.0415 9.95153V10.0472C1.04149 11.9708 1.04148 13.4784 1.19963 14.6546C1.36149 15.8585 1.69928 16.8086 2.44495 17.5542C3.19063 18.2999 4.14065 18.6377 5.34459 18.7996C6.52083 18.9577 8.02836 18.9577 9.95203 18.9577H10.0476C11.9713 18.9577 13.4788 18.9577 14.6551 18.7996C15.859 18.6377 16.8091 18.2999 17.5547 17.5542C18.3004 16.8086 18.6382 15.8585 18.8 14.6546C18.9582 13.4784 18.9582 11.9708 18.9582 10.0472V9.95154C18.9582 8.02788 18.9582 6.52034 18.8 5.3441C18.6382 4.14016 18.3004 3.19014 17.5547 2.44446C16.8091 1.69879 15.859 1.361 14.6551 1.19914C13.4788 1.041 11.9713 1.04101 10.0477 1.04102H9.95202ZM3.32883 3.32835C3.80355 2.85363 4.44582 2.58122 5.51115 2.43799C6.59447 2.29234 8.01798 2.29102 9.99984 2.29102C11.9817 2.29102 13.4052 2.29234 14.4885 2.43799C15.5539 2.58122 16.1961 2.85363 16.6708 3.32835C17.1456 3.80306 17.418 4.44533 17.5612 5.51066C17.7068 6.59398 17.7082 8.0175 17.7082 9.99935C17.7082 11.9812 17.7068 13.4047 17.5612 14.488C17.418 15.5534 17.1456 16.1956 16.6708 16.6704C16.1961 17.1451 15.5539 17.4175 14.4885 17.5607C13.4052 17.7064 11.9817 17.7077 9.99984 17.7077C8.01798 17.7077 6.59447 17.7064 5.51115 17.5607C4.44582 17.4175 3.80355 17.1451 3.32883 16.6704C2.85412 16.1956 2.58171 15.5534 2.43848 14.488C2.29283 13.4047 2.2915 11.9812 2.2915 9.99935C2.2915 8.0175 2.29283 6.59398 2.43848 5.51066C2.58171 4.44533 2.85412 3.80306 3.32883 3.32835Z"
				stroke="inherit"
			/>
		</svg>
	);
};
