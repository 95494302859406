import { FC } from 'react';

interface ImgCastom {
	alt?: string;
	role?: string;
}

export const Logo: FC<ImgCastom> = () => {
	return (
		<svg
			width="100"
			height="32"
			viewBox="0 0 100 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title id="logoTitle">на главную</title>
			<path
				d="M16.9357 5.95898H21.6688L14.4604 18.6028V24.9112H9.99938V18.6028L2.79102 5.95898H7.52406L12.2299 14.5687L16.9357 5.95898Z"
				fill="currentColor"
			/>
			<path
				d="M27.414 25.209C25.3467 25.209 23.633 24.5682 22.2729 23.2867C20.9129 21.9871 20.2328 20.2995 20.2328 18.2238C20.2328 16.1661 20.9038 14.4875 22.2457 13.1879C23.6058 11.8883 25.2832 11.2385 27.278 11.2385C29.2365 11.2385 30.8595 11.8522 32.1471 13.0796C33.4346 14.2889 34.0784 15.8863 34.0784 17.8718C34.0784 18.2328 34.0511 18.684 33.9967 19.2255H24.6123C24.7573 20.0738 25.0747 20.7146 25.5643 21.1478C26.0721 21.581 26.6796 21.7976 27.3868 21.7976C27.9852 21.7976 28.5021 21.6532 28.9373 21.3644C29.3725 21.0576 29.6445 20.6514 29.7533 20.146L33.5343 21.1478C33.1716 22.4835 32.4281 23.4943 31.3038 24.1802C30.1976 24.866 28.901 25.209 27.414 25.209ZM24.7483 16.5181H29.9165C29.8077 16.0307 29.5176 15.6065 29.0461 15.2456C28.5927 14.8665 28.0124 14.677 27.3052 14.677C26.0358 14.677 25.1835 15.2907 24.7483 16.5181Z"
				fill="currentColor"
			/>
			<path
				d="M48.541 21.4456C48.6861 21.4456 48.8946 21.4005 49.1666 21.3103V24.9653C48.7858 25.1097 48.2508 25.1819 47.5617 25.1819C45.8209 25.1819 44.6421 24.6946 44.0256 23.7199C43.0826 24.6946 41.7316 25.1819 39.9725 25.1819C38.5943 25.1819 37.47 24.8209 36.5996 24.0989C35.7473 23.3769 35.3211 22.3842 35.3211 21.1207C35.3211 19.9475 35.711 18.9999 36.4908 18.2779C37.2705 17.5559 38.3223 17.1317 39.6461 17.0054L43.5359 16.6534V16.3827C43.5359 15.8592 43.3546 15.4441 42.9919 15.1373C42.6292 14.8124 42.1668 14.6499 41.6046 14.6499C40.5891 14.6499 39.9816 15.1373 39.7821 16.1119L35.8923 15.5704C36.1281 14.0543 36.7991 12.9532 37.9052 12.2674C39.0296 11.5815 40.3715 11.2385 41.9311 11.2385C43.7807 11.2385 45.2315 11.6807 46.2833 12.5652C47.3532 13.4316 47.8882 14.668 47.8882 16.2744V20.7146C47.8882 21.202 48.1058 21.4456 48.541 21.4456ZM43.5359 19.6587V19.3609L40.979 19.6587C40.5438 19.7129 40.1992 19.8573 39.9454 20.0919C39.6915 20.3085 39.5645 20.5702 39.5645 20.8771C39.5645 21.2381 39.7187 21.5178 40.027 21.7164C40.3534 21.9149 40.7614 22.0052 41.251 21.9871C41.9039 21.951 42.4479 21.7344 42.8831 21.3373C43.3183 20.9402 43.5359 20.3807 43.5359 19.6587Z"
				fill="currentColor"
			/>
			<path
				d="M58.9538 11.2385C60.4771 11.2385 61.7193 11.6807 62.6804 12.5652C63.6597 13.4496 64.1493 14.686 64.1493 16.2744V24.9112H59.7971V17.601C59.7971 16.7888 59.5976 16.1841 59.1986 15.787C58.7997 15.3719 58.2556 15.1643 57.5665 15.1643C56.8774 15.1643 56.3243 15.3809 55.9073 15.8141C55.5083 16.2473 55.3088 16.8791 55.3088 17.7093V24.9112H50.9566V5.95898H55.3088V12.6193C56.1974 11.6988 57.4124 11.2385 58.9538 11.2385Z"
				fill="currentColor"
			/>
			<path
				d="M75.4413 11.5093H79.7935V24.9112H75.4413V23.8011C74.5527 24.7216 73.3377 25.1819 71.7963 25.1819C70.3455 25.1819 69.1124 24.7216 68.0969 23.8011C67.0995 22.8625 66.6008 21.5539 66.6008 19.8753V11.5093H70.953V18.9277C70.953 19.7038 71.1525 20.2904 71.5515 20.6875C71.9504 21.0666 72.4944 21.2561 73.1835 21.2561C73.8726 21.2561 74.4167 21.0395 74.8156 20.6063C75.2327 20.1731 75.4413 19.5414 75.4413 18.7111V11.5093Z"
				fill="currentColor"
			/>
			<path
				d="M90.5705 11.2385C92.4202 11.2385 93.9888 11.8973 95.2764 13.215C96.5639 14.5145 97.2077 16.1841 97.2077 18.2238C97.2077 20.2273 96.573 21.8878 95.3036 23.2055C94.0342 24.5231 92.4656 25.1819 90.5977 25.1819C89.0019 25.1819 87.6963 24.6946 86.6807 23.7199V24.9112H82.3285V5.95898H86.6807V12.6735C87.6781 11.7168 88.9747 11.2385 90.5705 11.2385ZM89.7001 21.4185C90.5343 21.4185 91.2415 21.1298 91.8218 20.5522C92.4021 19.9565 92.6922 19.1804 92.6922 18.2238C92.6922 17.2491 92.4021 16.4729 91.8218 15.8953C91.2596 15.2997 90.5524 15.0019 89.7001 15.0019C88.8297 15.0019 88.1043 15.2997 87.524 15.8953C86.9618 16.4729 86.6807 17.2491 86.6807 18.2238C86.6807 19.1804 86.9618 19.9565 87.524 20.5522C88.1043 21.1298 88.8297 21.4185 89.7001 21.4185Z"
				fill="currentColor"
			/>
		</svg>
	);
};
