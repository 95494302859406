import { FC } from 'react';

interface ImgCastom {
	alt?: string;
	role?: string;
}

export const Auto: FC<ImgCastom> = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10ZM10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1ZM5.88499 13.928C5.94099 13.976 6.00099 14 6.06499 14H7.54099C7.74099 14 7.87299 13.916 7.93699 13.748L8.39299 12.524H11.597L12.065 13.748C12.129 13.916 12.257 14 12.449 14H13.925C13.997 14 14.057 13.976 14.105 13.928C14.161 13.872 14.189 13.812 14.189 13.748L14.165 13.64L11.357 5.93598C11.333 5.83998 11.281 5.75998 11.201 5.69598C11.121 5.63198 11.017 5.59998 10.889 5.59998H9.11299C8.98499 5.59998 8.88099 5.63198 8.80099 5.69598C8.72099 5.75998 8.66899 5.83998 8.64499 5.93598L5.82499 13.64C5.81699 13.664 5.81299 13.7 5.81299 13.748C5.81299 13.812 5.83699 13.872 5.88499 13.928ZM10.001 7.62798L11.117 10.796H8.88499L10.001 7.62798Z"
				fill="currentColor"
			/>
		</svg>
	);
};
