import { FC } from 'react';

interface ImgCastom {
	alt?: string;
	role?: string;
}

export const Sun: FC<ImgCastom> = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 1C10.5523 1 11 1.44772 11 2V3.77778C11 4.33006 10.5523 4.77778 10 4.77778C9.44772 4.77778 9 4.33006 9 3.77778V2C9 1.44772 9.44771 1 10 1ZM14 10C14 12.2091 12.2091 14 10 14C7.79086 14 6 12.2091 6 10C6 7.79086 7.79086 6 10 6C12.2091 6 14 7.79086 14 10ZM11 16.2222C11 15.6699 10.5523 15.2222 10 15.2222C9.44771 15.2222 9 15.6699 9 16.2222V18C9 18.5523 9.44772 19 10 19C10.5523 19 11 18.5523 11 18V16.2222ZM1 10C1 9.44771 1.44772 9 2 9H3.77778C4.33006 9 4.77778 9.44771 4.77778 10C4.77778 10.5523 4.33006 11 3.77778 11H2C1.44772 11 1 10.5523 1 10ZM16.2222 9C15.6699 9 15.2222 9.44771 15.2222 10C15.2222 10.5523 15.6699 11 16.2222 11H18C18.5523 11 19 10.5523 19 10C19 9.44771 18.5523 9 18 9H16.2222ZM16.364 3.63603C16.7545 4.02656 16.7545 4.65972 16.364 5.05025L15.1069 6.30733C14.7164 6.69785 14.0832 6.69785 13.6927 6.30733C13.3022 5.9168 13.3022 5.28364 13.6927 4.89311L14.9498 3.63603C15.3403 3.24551 15.9734 3.24551 16.364 3.63603ZM6.30733 15.1069C6.69785 14.7164 6.69785 14.0832 6.30733 13.6927C5.9168 13.3021 5.28364 13.3021 4.89311 13.6927L3.63603 14.9498C3.24551 15.3403 3.24551 15.9734 3.63603 16.364C4.02656 16.7545 4.65972 16.7545 5.05025 16.364L6.30733 15.1069ZM3.63603 3.63603C4.02656 3.24551 4.65972 3.24551 5.05025 3.63603L6.30733 4.89311C6.69785 5.28364 6.69785 5.9168 6.30733 6.30733C5.9168 6.69785 5.28364 6.69785 4.89311 6.30733L3.63603 5.05025C3.24551 4.65972 3.24551 4.02656 3.63603 3.63603ZM15.1069 13.6927C14.7164 13.3021 14.0832 13.3021 13.6927 13.6927C13.3021 14.0832 13.3021 14.7164 13.6927 15.1069L14.9498 16.364C15.3403 16.7545 15.9734 16.7545 16.364 16.364C16.7545 15.9734 16.7545 15.3403 16.364 14.9498L15.1069 13.6927Z"
				fill="currentColor"
			/>
		</svg>
	);
};
