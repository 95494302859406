import { FC } from 'react';

interface ImgCastom {
	alt?: string;
	role?: string;
}

export const Moon: FC<ImgCastom> = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17.359 13.1429C16.3619 13.6893 15.2172 14 14 14C10.134 14 7 10.866 7 6.99999C7 5.07018 7.78092 3.32277 9.04398 2.05652C5.0764 2.529 2 5.90522 2 9.99999C2 14.4183 5.58172 18 10 18C13.3026 18 16.1377 15.9988 17.359 13.1429Z"
				fill="currentColor"
			/>
		</svg>
	);
};
